/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';


export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'statistics',
        title: 'Statistici',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/statistici'
    },
    {
        id   : 'courses',
        title: 'Cursuri',
        type : 'basic',
        icon : 'heroicons_outline:book-open',
        link : '/cursuri'
    },
    {
        id   : 'files',
        title: 'Fișiere',
        type : 'basic',
        icon : 'heroicons_outline:folder',
        link : '/fisiere'
    }
];

export const adminParentNavigation: FuseNavigationItem[] = [
    {
        id   : 'statistics',
        title: 'Statistici',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/statistici'
    },
    {
        id   : 'files',
        title: 'Fișiere',
        type : 'basic',
        icon : 'heroicons_outline:folder',
        link : '/fisiere'
    },
    {
        id   : 'media',
        title: 'Media',
        type : 'basic',
        icon : 'heroicons_outline:photo',
        link : '/media'
    },
    {
        id   : 'courses',
        title: 'Cursuri',
        type : 'basic',
        icon : 'heroicons_outline:book-open',
        link : '/cursuri'
    },
    {
        id   : 'simulations',
        title: 'Simulări',
        type : 'basic',
        icon : 'heroicons_outline:calculator',
        link : '/simulari'
    },
    {
        id   : 'workshops',
        title: 'Ateliere',
        type : 'basic',
        icon : 'heroicons_outline:beaker',
        link : '/ateliere'
    },
    {
        id   : 'timetable',
        title: 'Orar',
        type : 'basic',
        icon : 'heroicons_outline:calendar-days',
        link : '/orar'
    },
    {
        id   : 'users',
        title: 'Utilizatori',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/utilizatori',
    },
    {
        id   : 'orders',
        title: 'Comenzi',
        type : 'basic',
        icon : 'heroicons_outline:credit-card',
        link : '/comenzi'
    },
    {
        id   : 'physical-products',
        title: 'Produse',
        type : 'basic',
        icon : 'heroicons_outline:shopping-bag',
        link : '/produse-fizice'
    },
    {
        id   : 'vouchers',
        title: 'Cupoane',
        type : 'basic',
        icon : 'heroicons_outline:gift',
        link : '/cupoane'
    },
    {
        id   : 'share',
        title: 'Distribuie',
        type : 'basic',
        icon : 'heroicons_outline:share',
        link : '/share'
    },
    {
        id   : 'settings',
        title: 'Setări',
        type : 'basic',
        icon : 'heroicons_outline:cog-6-tooth',
        link : '/setari'
    },
];
export const adminTeacherNavigation: FuseNavigationItem[] = [

];
