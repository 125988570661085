import { inject, Injectable } from '@angular/core';
import { Navigation } from 'app/core/navigation/navigation.types';
import { Observable, ReplaySubject, Subject, takeUntil } from 'rxjs';
import { UserService } from '../user/user.service';
import { User } from '../user/user.types';
import { cloneDeep } from 'lodash';
import { adminParentNavigation, adminTeacherNavigation, defaultNavigation } from './data';

@Injectable({ providedIn: 'root' })
export class NavigationService {
    private _userService = inject(UserService);
    private _navigation: ReplaySubject<Navigation> =
        new ReplaySubject<Navigation>(1);

    private _unsubscribeAll: Subject<any> = new Subject<any>();

    private _user: User;

// -----------------------------------------------------------------------------------------------------
    // @ Accessors
    // -----------------------------------------------------------------------------------------------------

    /**
     * Getter for navigation
     */
    get navigation$(): Observable<Navigation>
    {
        return this._navigation.asObservable();
    }

    /**
     * Getter for navigation
     */
     get(): Observable<Navigation>
     {
        // Subscribe to navigation data
        this._userService.user$
            .pipe(takeUntil(this._unsubscribeAll))
            .subscribe((user: User) => {
                this._user = user;
            });

        let navigation = {
            default   : cloneDeep(defaultNavigation),
        };

        if (this._user?.type === 'parent'){
            navigation = {
                default   : cloneDeep([...defaultNavigation, ...adminParentNavigation])
            };
        } else if (this._user?.type === 'teacher'){
            navigation = {
                default   : cloneDeep([...defaultNavigation, ...adminTeacherNavigation])
            };
        }

        this._navigation.next(navigation);

         return this._navigation.asObservable();
     }

    /**
     * On destroy
     */
    ngOnDestroy(): void
    {
        // Unsubscribe from all subscriptions
        this._unsubscribeAll.next(null);
        this._unsubscribeAll.complete();
    }
}
