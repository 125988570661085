/* eslint-disable */
import { FuseNavigationItem } from '@fuse/components/navigation';


export const defaultNavigation: FuseNavigationItem[] = [
    {
        id   : 'statistics',
        title: 'Statistici',
        type : 'basic',
        icon : 'heroicons_outline:chart-pie',
        link : '/statistici'
    },
    {
        id   : 'courses',
        title: 'Cursuri',
        type : 'basic',
        icon : 'heroicons_outline:book-open',
        link : '/cursuri'
    },
    {
        id   : 'files',
        title: 'Fișiere',
        type : 'basic',
        icon : 'heroicons_outline:folder',
        link : '/fisiere'
    }
];

export const adminParentNavigation: FuseNavigationItem[] = [
    {
        id   : 'users',
        title: 'Utilizatori',
        type : 'basic',
        icon : 'heroicons_outline:user-group',
        link : '/utilizatori'
    },
    {
        id   : 'orders',
        title: 'Comenzi',
        type : 'basic',
        icon : 'heroicons_outline:credit-card',
        link : '/comenzi'
    },
];
export const adminTeacherNavigation: FuseNavigationItem[] = [

];
